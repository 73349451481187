<template>
<div class="personnel" >
  <gt-header checked="rczp"></gt-header>
  <div class="banner">
    <img src="../../assets/newImgs/personnel/banner.png">
    <div class="txtMain">
      <div class="lineOne">人才招聘<i class="el-icon-caret-right"></i></div>
      <div class="lineTwo">Talent recruitment</div>
    </div>
  </div>
  <div class="perCenter"  id="recruitment">
  <div class="frameMain">
    <div class="lineOne">
      <img src="../../assets/newImgs/personnel/zp.png">
    </div>
    <div class="lineTwo">
      <div class="lineLeft">
        招聘岗位
      </div>
      <div class="lineRight">
        <div class="frameTop">
   <div class="lineTop">后端开发工程师，前端开发工程师，UI设计师</div>
        <div class="lineBom">
          <div class="lineTitle">
            工作待遇：
          </div>
          <div class="lineContent">
            底薪+绩效奖金+各类补贴
          </div>
          <br/>
          <div class="lineTitle">
            专业要求：
          </div>
          <div class="lineContent">
            （C语言/C++/JAVA等）、数据库技术（SQL/ORACLE/DB2等）、.NET平台技术、C#、C/S B/S程序开发，还有诸多如
            JAVA SCRIPT、AJAX、HIBERNATE、SPRING、J2EE、WEB SERVICE、STRUTS等前沿技术。
          </div>
        </div>
        </div>
        <div class="frameBom">
         <div class="bomLeft">
           投递简历请附上以下几种材料：
         </div>
          <div class="bomRight">
            <div class="bomEver">
              ①个人简历
            </div>
            <div class="bomEver">
             ②毕业证书复印件
            </div>
            <div class="bomEver">
              ③各类资质证书复印件
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="perBg"></div>

  </div>
  <div class="concat" id="concat">
    <div class="concatTitle">
      联系方式
    </div>
    <div class="concatMain">
      <div class="boxEver">
        <div class="icon">
          <img src="../../assets/newImgs/personnel/icon1.png">
        </div>
        <div class="iconTxt">
          投递邮箱<br/>
          info@gtelec.net
        </div>
      </div>
      <div class="fakerBox"></div>
      <div class="boxEver">
        <div class="icon">
          <img src="../../assets/newImgs/personnel/icon2.png">
        </div>
        <div class="iconTxt">
          联系人<br/>
          穆经理
        </div>
      </div>
      <div class="fakerBox"></div>

      <div class="boxEver">
        <div class="icon">
          <img src="../../assets/newImgs/personnel/icon3.png">
        </div>
        <div class="iconTxt">
          联系电话<br/>
          0571-88362200
        </div>
      </div>
    </div>
  </div>
  <gt-footer></gt-footer>
</div>
</template>

<script>
import GtHeader from "../../components/gtHeader";
import GtFooter from "../../components/gtFooter";
export default {
  name: "personnel",
  components: {GtFooter, GtHeader}
}
</script>

<style lang="scss" scoped>
.personnel{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width:10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb{
    border-radius:5px;
    background:rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track{
    border-radius:0px;
    height:100px;
    background:rgba(167, 198, 156,0.3);
  }
}
.banner{
  position: relative;
  width: 1920px;
  height: 800px;
  img{
    width: 1920px;
    height: 800px;
  }
  .txtMain{
    position: absolute;
    left: 400px;
    top: 430px;
    .lineOne{
      font-size: 72px;
      color: white;
      margin-bottom: 10px;
    }
    .lineTwo{
      font-size: 32px;
      color: white;
    }
  }
}
.perCenter{
  position: relative;
  width: 1920px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F4F4;
  .frameMain{
    position: relative;
    width: 1100px;
    height: 500px;
    .lineOne{
      margin-bottom: 70px;
    }
    .lineTwo{
      width: 1100px;
      height: 300px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .lineLeft{
        width: 200px;
        height: 50px;
        background: #A7C69C;
        color: white;
        text-align: center;
        line-height: 50px;
        font-size: 24px;

      }
      .lineRight{
        width: 800px;
        height:350px;
        z-index: 99;
        .frameTop{
          width: 800px;
          min-height:250px;
          background:white;
          padding: 20px 40px;
          box-sizing: border-box;
          border-radius: 10px;
        }
        .lineTop{
          width: 100%;
          font-size: 16px;
          color: #666666;
          border-bottom: 1px solid lightgray;
          line-height: 50px;
          margin-bottom: 30px;
        }
        .lineBom{
          width: 100%;
          .lineTitle{
          font-size: 16px;
            font-weight: bold;
            color: black;
            margin-bottom: 5px;
          }
          .lineContent{
    font-size: 14px;
            color: #666666;
          }
        }
        .frameBom{
          width: 100%;
          display: flex;
          padding-left: 20px;
          margin-top: 20px;
box-sizing: border-box;
          .bomLeft{
           font-size: 16px;
            color: #666666;
            margin-right: 10px;
          }
          .bomRight{
            display: flex;

            box-sizing: border-box;
                .bomEver{
                  color: #A7C69C;
                  font-size: 16px;
                  margin-right: 30px;

                }
          }
        }
      }
    }
  }
}
.concat{
  position: relative;
  width: 1920px;
  height: 400px;
  padding-top: 40px;
  box-sizing: border-box;
  background: url("../../assets/newImgs/personnel/bomBanner.png");
  background-size: 100% 100%;
}
.concatTitle{
  position: relative;
  font-size: 36px;
  color: white;
  min-width: 3vw;
  text-align: center;
  margin: 0 auto;
}
.concatMain{
  width: 1100px;
  height: 180px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  margin-top: 70px;
  .boxEver{
    position: relative;
    width: 120px;
    height: 180px;
    .icon{
   width: 120px;
      height: 120px;
      img{
        width: 120px;
        height: 120px;
      }
    }
    .iconTxt{

      width: 100%;
      font-size: 16px;
      color: white;
      text-align: center;
      margin-top: 15px;
      line-height: 24px;
    }
  }
}
.perBg{
  position: absolute;
  top: 80px;
  right: 0;
  width:1220px;
  height: 450px;
  background: url("../../assets/newImgs/personnel/centerBanner.png");
  background-size: 100% 100%;
}
.fakerBox{
  height: 80px;
  width: 2px;
  background:white;
  transform: translateY(-10px);
}
</style>
